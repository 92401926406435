:root {
  --modal-base-padding: 40px;
}


*{
  scrollbar-width: none;
  -ms-overflow-style: none;
  outline-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.app-bar-modal-base {
  padding: var(--modal-base-padding);
}

.app-bar-modal-close {
  padding: calc(var(--modal-base-padding) - 8px);
}

@media only screen and (max-width: 600px) {
  :root {
    --modal-base-padding: 25px;
  }  
}

/* Google button */

.google-btn {
  width: 100% !important;
  background-color:white !important;
  height: 56px;
  font-family: Roboto,Helvetica,Arial,sans-serif;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  background: 0 0;
  border: none;
  border-radius: 2px;
  position: relative;
  margin: 0;
  padding: 0 !important;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0;
  overflow: hidden;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
  list-style: none;
  color: rgba(0,0,0,.87);
  direction: ltr;
  font-weight: 500;
  line-height: normal;
  text-align: left;
  border-radius: 0 !important;
}
.google-btn:focus,.google-btn:visited,.google-btn:active,.google-btn:hover{
  outline: none;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,.18),0 4px 2px -3px rgba(0,0,0,.25),0 2px 6px 0 rgba(0,0,0,.15);
}
.google-img {
  border: none;
  display: inline-block;
  height: 22px;
  vertical-align: middle;
  width: 22px;
}
.google-btn-text-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.google-btn-text {
  color: #757575;
  display: table-cell;
  font-size: 14px;
  padding-left: 16px;
  text-transform: none;
  vertical-align: middle;
}
.google-btn-text.google-btn-text-long {
  display: table-cell;
}
.google-btn-text.google-btn-text-short {
  display: none;
}
body {
  padding: 0;
  margin: 0;
}

.content {
  /* max-width: 900px; */
  width: min(90%, 900px);
  padding:8px;
}

.markdown {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #212121;
}

.markdown a:link{
  color: #26A69A;
}

.markdown a:visited{
  color: #1A746B;
}
